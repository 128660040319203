@tailwind base;
@tailwind components;
@tailwind utilities;

/* https://www.onlinewebfonts.com/download/3f3f1a887702b525cda01f7cd776b241 */
@layer base {
  @font-face {
    font-family: 'Gadugi';
    src: url("https://db.onlinewebfonts.com/t/3f3f1a887702b525cda01f7cd776b241.woff2") format("woff2"),
    }
}